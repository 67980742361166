<template>
    <div class="paddbox autobox">
      <el-card>
        <el-date-picker
          v-model="date"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          :default-value="new Date()"
          value-format="YYYY-MM-DD"
          @change="dateChange"
        >
        </el-date-picker>
      </el-card>
      <el-card>
         <marquee
   direction="right"
   style="color:red;font-size:18px;"
   ><i class="el-icon-message-solid"></i
   >创建详细信息请跳转《巨量推广创建信息》页面</marquee>
  
        <div>
          <div>
            <!-- 选择器和按钮 查询账号 -->
          </div>
          <div>
            <el-table
              :data="tableData"
              style="width: 100%;margin-bottom: 20px;"
              border
            >
              <el-table-column prop="id" label="任务id" width="80">
              </el-table-column>
              <el-table-column prop="name" label="定时备注" width="500">
              </el-table-column>
              <el-table-column prop="publishTime" label="预定发布时间" width="200">
              </el-table-column>
              <el-table-column label="类型"  width="150">
                <template #default="scope">
                  <span>{{
                     scope.row.type == 1
                      ? '项目'
                      : '广告'
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="状态"  width="150">
                <template #default="scope">
                  <div
                    :style="
                         scope.row.status == 0
                        ? 'color: rgb(144, 147, 153)'
                        : 'color:  rgb(103, 194, 58)'
                    "
                  >
                    {{
                         scope.row.status == 0
                        ? '未发布'
                        : '已发布'
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="200">
              </el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                    <el-button v-if="scope.row.status==0" type="danger" size="mini" @click="deleteToutiaoScheduleInfoFun(scope.row.id)" plain>删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="pagination">
          <pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :total="total"
            :pagesize="pagesize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
  // 分页组件
  import pagination from '@/components/pagination.vue'
  import {
    selectToutiaoScheduleInfo,
    deleteToutiaoScheduleInfo,
  } from '@/request/new/videoBatchManagement'
  
  import { ElMessage } from 'element-plus'
  import { inject, reactive, toRefs, watch } from 'vue'
  
  export default {
    name: 'toutiaoScheduleCreate',
    components: { pagination, ElMessage },
    setup() {
      const state = reactive({
        // 日期
        date: [],
        // 表格数据
        tableData: [],
        // 条数
        pagesize: 20,
        // 当前页数
        currentPage: 1,
        // 总数
        total: 100,
        // 分页开始
        // 页数 发生改变
        handleCurrentChange: (e) => {
          state.currentPage = e
          selectToutiaoScheduleInfoFun()
        },
        // 个数 发生改变
        handleSizeChange: (e) => {
          state.pagesize = e
          selectToutiaoScheduleInfoFun()
        },
        //日期改变
        dateChange: () => {
          selectToutiaoScheduleInfoFun()
        },
        //删除
        deleteToutiaoScheduleInfoFun:(data)=>{
            const datas = {id:data}
            deleteToutiaoScheduleInfo(datas).then((res)=>{
                ElMessage({
            type: 'success',
            message: '删除成功',
            })
                selectToutiaoScheduleInfoFun()
            })
        }
      })
  
      // 默认日期
      const setCurrentDate = () => {
        const s = new Date().getTime()
        const date = new Date(s)
        // 年
        const year = date.getFullYear()
        // 月
        const month =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        // 日
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  
        state.date = [
          year + '-' + month + '-' + day,
          year + '-' + month + '-' + day,
        ]
  

      }
      setCurrentDate()
  
      const selectToutiaoScheduleInfoFun = () => {
        const datas = {
          startDate: state.date[0],
          endDate: state.date[1],
          currentPage: state.currentPage,
          size: state.pagesize,
        }
        selectToutiaoScheduleInfo(datas).then((res) => {
          state.tableData = res.data.data
          state.currentPage = res.data.currenPage
          state.total = res.data.totalSize
        })
      }
  
      selectToutiaoScheduleInfoFun()
  
      return {
        // 结构赋值，暴露出去就不用 state.xx
        ...toRefs(state),
        selectToutiaoScheduleInfoFun,
        setCurrentDate,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .liLists {
    > li {
      margin-bottom: 10px;
    }
  }
  
  .Success {
    color: rgb(103, 194, 58);
  }
  
  .Error {
    color: rgb(245, 108, 108);
  }
  </style>
  